import { useQuery } from "@tanstack/react-query"
import { useEffect, useRef } from "react"

import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import fetchObjectListData from "@utils/fetchObjectListData"

import { TabItem } from "@atoms"

import useTable from "@organisms/Table/Table.context"

import { LIST_SEARCH_PARAM_ACTIVE_TAB } from "@constants/searchParams"

import { TableViewTabProps } from "./TableViewTab.types"

const activeTabOverride = extractURLSearchParam(LIST_SEARCH_PARAM_ACTIVE_TAB)

export default function TableViewTab(props: TableViewTabProps) {
    const { id, title, phase, countEndpoint, endpointKwargs, listEndpoint, isDefaultActiveTabForPhase, hide } = props

    const { activePhase, activeTab, setActiveTab, searchKeywords, filters, setActivePhase } = useTable()
    const { isLoading } = useUser()
    const hasSetTabFromUrl = useRef(false)

    const [tabCountEndpoint, tabListEndpoint] = useSlugExtractor([countEndpoint, listEndpoint])

    const { data } = useQuery({
        queryKey: [tabCountEndpoint, endpointKwargs, searchKeywords, filters, title],
        queryFn: () =>
            fetchObjectListData({
                objectName: `${title} Count`,
                endpoint: tabCountEndpoint,
                endpointKwargs: endpointKwargs,
                searchKeywords: searchKeywords,
                filters: filters,
            }),
        staleTime: 60000,
    })

    const onSetActiveTab = () => {
        setActivePhase(phase)

        setActiveTab({
            id,
            title,
            phase,
            countEndpoint: tabCountEndpoint,
            endpointKwargs,
            listEndpoint: tabListEndpoint,
        })
    }

    const isUrlActivated = activeTabOverride === title && !hasSetTabFromUrl.current

    const isLegacyActivated = typeof activeTab === "string" && activeTab === title

    const listEndpointHasChanged =
        activeTab?.listEndpoint !== tabListEndpoint &&
        !!activeTab?.listEndpoint &&
        activeTab?.id === id &&
        activeTab?.phase === phase

    const isDefaultActivated = isDefaultActiveTabForPhase && activePhase === phase && activeTab?.phase !== phase

    useEffect(() => {
        if (isLoading) {
            return
        }

        const shouldActivateTab = isUrlActivated || isLegacyActivated || isDefaultActivated || listEndpointHasChanged

        if (shouldActivateTab) {
            onSetActiveTab()

            hasSetTabFromUrl.current = true
        }
    }, [
        isLoading,
        activeTabOverride,
        id,
        activeTab,
        title,
        isDefaultActiveTabForPhase,
        activePhase,
        phase,
        onSetActiveTab,
        listEndpointHasChanged,
    ])

    if (activePhase !== phase || hide) {
        return null
    }

    return (
        <TabItem count={data?.count} isPressed={activeTab?.id === id} onClick={onSetActiveTab} noDebounce={true}>
            {title}
        </TabItem>
    )
}
